import Slice from "./Slice";
import React from "react";
import {Property} from "csstype";

const slicesGap = 0.01;
const topLabelGap = 15;
const bottomLabelGap = 10;

export interface ISlice {
  percent: number,
  color: Property.Color,
  fill: Property.Color,
  size: number,
  label: string,
  link?: string
}

export class SlicesManager {
  private cumulativePercent = 0;
  private slices;

  constructor() {
    this.slices = new Map();
  }

  addSlice(slice: ISlice, key: React.Key) {
    const atBottom = this.cumulativePercent > .5;

    const cumulativePercentStart = this.cumulativePercent;
    const cumulativePercentEnd = this.cumulativePercent + slice.percent;
    this.cumulativePercent = cumulativePercentEnd + slicesGap;
    const labelGap = atBottom ? bottomLabelGap : topLabelGap;

    const sliceComponent = Slice({
      slice,
      key,
      atBottom,
      cumulativePercentStart,
      cumulativePercentEnd,
      labelGap
    });

    this.slices.set(key, sliceComponent);
    return sliceComponent;
  }

}