import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import {ISlice, SlicesManager} from "./SlicesManager";
import './App.css';

const slices: ISlice[] = [
  {percent: 0.092, fill: '#00aa00', color: '#444', size: 85, label: 'HTML'},
  {percent: 0.092, fill: '#1e90ff', color: '#444', size: 75, label: 'CSS'},
  {percent: 0.092, fill: '#bf55ec', color: '#444', size: 80, label: 'JS'},
  {percent: 0.092, fill: '#aa8f00', color: '#444', size: 70, label: 'React'},
  {percent: 0.092, fill: '#e73c4e', color: '#444', size: 82, label: 'NodeJS'},

  {percent: 0.088, fill: '#634806', color: '#ddd', size: 60, label: 'Facebook', link: 'https://www.facebook.com/RostyslavSulytskyi'},
  {percent: 0.088, fill: '#634806', color: '#ddd', size: 60, label: 'Instagram', link: 'https://www.instagram.com/rem99/'},
  {percent: 0.088, fill: '#634806', color: '#ddd', size: 60, label: 'LinkedIn', link: 'https://www.linkedin.com/in/rem99/'},
  {percent: 0.088, fill: '#634806', color: '#ddd', size: 60, label: 'CV', link: 'https://docs.google.com/document/d/1c2g-PRtp7O5hwdE85AF7FxkrELu-ypWcAXOmKnwI7CQ'},
  {percent: 0.088, fill: '#634806', color: '#ddd', size: 60, label: 'GitLab', link: 'https://gitlab.com/rostik155/'},
];

function App() {
  const slicesManager = new SlicesManager();

  const [isReady, setReadyStatus] = useState<boolean>(false);

  const [slicesData, setSlicesData] = useState<ISlice[]>(slices.map(slice => Object.assign({}, slice, {size: 0})));

  const classes = classnames('App', {
    'ready': isReady
  });

  useEffect(() => {
    setReadyStatus(true);
    setSlicesData(slices);
  }, []);

  return (
    <div className={classes}>
      <svg viewBox="-105 -105 210 210">
        <g className="slices-group">
          {slicesData.map(slicesManager.addSlice.bind(slicesManager))}
        </g>
        <defs>
          <pattern id="image" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 30 30">
            <image x="0%" y="0%" width="30" height="30" xlinkHref="photo2.jpg" />
          </pattern>
        </defs>
        <circle r="30" cx="0" cy="0" fill="white" />
        <circle className="image" r="28" cx="0" cy="0" fill="url(#image)" />
      </svg>
    </div>
  );
}

export default App;
