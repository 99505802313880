import React, {useState} from 'react';
import {ISlice} from "./SlicesManager";

export interface ISliceProps {
  slice: ISlice,
  key: React.Key,
  atBottom: boolean,
  cumulativePercentStart: number,
  cumulativePercentEnd: number
  labelGap: number
}

function getCoordinatesForPercent(percent: number, size: number) {
  const x = Math.cos(2 * Math.PI * percent) * size;
  const y = Math.sin(2 * Math.PI * percent) * size;
  return [x, y];
}

function Slice({
  slice,
  key,
  atBottom,
  cumulativePercentStart,
  cumulativePercentEnd,
  labelGap
}: ISliceProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const size = (isHovered && slice.link) ? slice.size + 5 : slice.size;

  const [startX, startY] = getCoordinatesForPercent(cumulativePercentStart, size);
  const [textStartX, textStartY] = getCoordinatesForPercent(cumulativePercentStart, slice.size - labelGap);

  const [endX, endY] = getCoordinatesForPercent(cumulativePercentEnd, size);
  const [textEndX, textEndY] = getCoordinatesForPercent(cumulativePercentEnd, slice.size - labelGap);

  const largeArcFlag = slice.percent > .5 ? 1 : 0;

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const pathData = [
    `M ${startX} ${startY}`,
    `A ${size} ${size} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
    `L 0 0 Z`,
  ].join(' ');

  let textPathData;
  if (atBottom) {
    textPathData = [
      `M ${textEndX} ${textEndY}`,
      `A ${slice.size - labelGap} ${slice.size - labelGap} ${largeArcFlag} 0 0 ${textStartX} ${textStartY} Z`
    ].join(' ');
  } else {
    textPathData = [
      `M ${textStartX} ${textStartY}`,
      `A ${slice.size} ${slice.size} 0 ${largeArcFlag} 1 ${textEndX} ${textEndY} Z`,
    ].join(' ');
  }

  return <a href={slice.link} key={key} target="_blank" rel="noreferrer">
    <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <path d={pathData} fill={slice.fill} className="slicePath" />
      <defs>
        <path id={`p${key}`} d={textPathData} fill={slice.fill} key={key} />
      </defs>
      <text style={{fontSize: '6.3px', fill: slice.color}}>
        <textPath xlinkHref={`#p${key}`} startOffset="25%" textAnchor="middle">{slice.label}</textPath>
      </text>
    </g>
  </a>;
}

export default Slice;
